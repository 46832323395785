import { SponsorsIcon } from "./styles";

export const LSD = () => {
  return (
    <a href="https://www.lsd.ufcg.edu.br/#/" target="_blank" rel="noreferrer">
      <SponsorsIcon
        width={100}
        height={89}
        viewBox="0 0 100 89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1432_717)">
          <path
            d="M82.1584 61.0629L21.9631 61.2798C17.0282 61.2798 11.8764 63.7202 11.8764 69.2516C11.8764 74.7831 17.0824 77.3861 22.0174 77.3861L68.167 77.2234C70.1735 77.2234 73.4816 78.0911 73.4816 80.5857C73.4816 83.1345 70.282 83.9479 68.384 83.9479L4.98915 84.0022L4.77224 62.7983C4.77224 61.4967 3.63341 60.3579 2.33189 60.3579C1.03037 60.3579 -0.0542297 61.4967 -0.0542297 62.7983L2.03464e-07 86.3883C2.03464e-07 87.6898 1.24729 88.7202 2.38612 88.7202L82.2126 88.6659C90.564 88.6117 99.9458 84.2191 99.9458 74.6746C99.9458 65.1301 90.5098 61.0087 82.1584 61.0629ZM82.2668 83.8395H77.4946C78.0369 82.9176 78.308 81.833 78.308 80.4772C78.308 74.9458 73.102 72.2885 68.167 72.2885L22.0174 72.4512C20.0108 72.4512 16.7028 71.6377 16.7028 69.1432C16.7028 66.5401 19.9566 65.7267 21.9631 65.7267L82.1584 65.5098C87.744 65.5098 95.1193 68.0043 95.1735 74.6204C95.1735 81.3449 87.7983 83.8395 82.2668 83.8395Z"
            fill="white"
          />
          <path
            d="M62.256 42.5162C62.256 44.2516 61.4968 45.1192 60.141 45.1192C58.731 45.1192 58.026 44.1973 58.026 42.245V36.334H55.4772V42.5704C55.4772 45.77 57.2126 47.5053 60.0325 47.5053C62.7983 47.5053 64.5879 45.77 64.5879 42.5162V36.334H62.256V42.5162Z"
            fill="white"
          />
          <path
            d="M58.6226 22.2886V20.0652H52.0065V30.8569H58.731V28.5793H54.5011V26.4643H58.026V24.2409H54.5011V22.2886H58.6226Z"
            fill="white"
          />
          <path
            d="M2.92842 36.334H0.0542297V47.18H2.92842C4.39262 47.18 5.58568 47.0173 6.72451 46.3665C8.45987 45.3362 9.38178 43.7635 9.38178 41.757C9.43601 38.5032 7.10412 36.334 2.92842 36.334ZM3.19957 44.7939H2.65727V38.6116H3.19957C5.53145 38.6116 6.83297 39.8589 6.83297 41.7027C6.77874 43.6008 5.53145 44.7939 3.19957 44.7939Z"
            fill="white"
          />
          <path
            d="M74.0781 36.334H71.2039V47.18H74.0781C75.5423 47.18 76.7354 47.0173 77.8742 46.3665C79.6096 45.3362 80.5315 43.7635 80.5315 41.757C80.5315 38.5032 78.2538 36.334 74.0781 36.334ZM74.295 44.7939H73.7527V38.6116H74.295C76.6269 38.6116 77.9284 39.8589 77.9284 41.7027C77.9284 43.6008 76.6269 44.7939 74.295 44.7939Z"
            fill="white"
          />
          <path
            d="M78.0911 19.7397H77.9284L72.397 30.9111H75L75.705 29.3384H80.0976L80.8026 30.9111H83.5141L78.0911 19.7397ZM76.4642 27.4404L77.9284 24.0239L79.3384 27.4404H76.4642Z"
            fill="white"
          />
          <path
            d="M6.45336 30.0433C8.18872 29.0129 9.11063 27.4402 9.11063 25.4337C9.11063 22.1799 6.77874 20.0107 2.60304 20.0107H0.271149V30.8567H2.65727C4.12147 30.8567 5.31453 30.7483 6.45336 30.0433ZM1.57267 21.258H2.92842C6.12798 21.258 7.86334 22.8849 7.86334 25.488C7.86334 28.091 6.12798 29.7179 2.92842 29.7179H1.57267V21.258Z"
            fill="white"
          />
          <path
            d="M97.6139 40.5639L96.3666 39.8589C95.8785 39.5877 95.6074 39.2624 95.6074 38.8828C95.6074 38.3405 96.0412 38.0151 96.8004 38.0151C97.397 38.0151 98.3189 38.3405 99.4577 39.0454V36.4966C98.4273 36.1713 97.5054 35.9543 96.7462 35.9543C94.6312 35.9543 93.2213 37.2559 93.2213 38.937C93.2213 40.2385 93.872 41.2146 95.3362 42.0281L96.0954 42.4619C97.18 43.1127 97.885 43.3838 97.885 44.1431C97.885 44.7396 97.3427 45.1734 96.4208 45.1734C95.3905 45.1734 94.4685 44.7396 93.3839 44.0888L93.6009 46.963C94.6855 47.2884 95.6074 47.5053 96.475 47.5053C98.9154 47.5053 100.434 45.9869 100.434 43.9804C100.325 42.245 99.1866 41.4316 97.6139 40.5639Z"
            fill="white"
          />
          <path
            d="M52.0065 41.3774C53.1996 40.9435 53.7961 40.1843 53.7961 39.0455C53.7961 37.2559 52.4946 36.334 50 36.334H46.6378V47.18H49.5662C52.6031 47.18 54.1215 46.0954 54.1215 44.0346C54.1215 42.6789 53.3623 41.6485 52.0065 41.3774ZM48.9696 38.1778H49.7831C50.9219 38.1778 51.4642 38.5032 51.4642 39.3709C51.4642 40.1843 50.8677 40.6181 49.8373 40.6181H48.9696V38.1778ZM50.0542 45.1735H48.9696V42.4077H50.0542C51.1388 42.4077 51.7354 42.8958 51.7354 43.7635C51.7354 44.6312 51.0846 45.1735 50.0542 45.1735Z"
            fill="white"
          />
          <path d="M13.449 36.334H10.846V47.18H13.449V36.334Z" fill="white" />
          <path
            d="M69.8482 30.8569H72.2885L70.0108 19.6855H69.8482L65.9436 26.7354L61.8764 19.6855H61.7137L59.436 30.8569H61.7679L62.6898 25.8678L65.8351 31.2908H65.9978L68.9262 25.922L69.8482 30.8569Z"
            fill="white"
          />
          <path
            d="M69.1432 36.334H66.5401V47.18H69.1432V36.334Z"
            fill="white"
          />
          <path d="M44.577 36.334H41.974V47.18H44.577V36.334Z" fill="white" />
          <path
            d="M86.8221 35.9543C85.3579 35.9543 83.9479 36.5509 82.9176 37.5812C81.833 38.6658 81.2907 40.1301 81.2907 41.7027C81.2907 43.3296 81.833 44.7396 82.9176 45.8242C83.9479 46.9088 85.3579 47.4511 86.8221 47.4511C88.5575 47.4511 90.1302 46.6376 91.2148 45.2277C91.974 44.2515 92.3536 43.0042 92.3536 41.7027C92.3536 40.0758 91.8113 38.6658 90.7267 37.5812C89.6963 36.5509 88.2863 35.9543 86.8221 35.9543ZM86.8221 45.065C85.2495 45.065 83.948 43.655 83.948 41.7569C83.948 39.8047 85.1952 38.4489 86.8221 38.4489C88.3948 38.4489 89.6963 39.8589 89.6963 41.7569C89.6963 43.655 88.3948 45.065 86.8221 45.065Z"
            fill="white"
          />
          <path
            d="M66.757 35.0866H67.7332L69.9024 32.5378H68.2755L66.757 35.0866Z"
            fill="white"
          />
          <path
            d="M93.6009 14.9674C95.2278 14.9674 96.8004 14.154 97.8308 12.744C98.5358 11.7679 98.9154 10.5206 98.9154 9.21908C98.9154 7.59218 98.3731 6.1822 97.3427 5.0976C96.3124 4.013 95.0109 3.4707 93.6009 3.4707C92.1909 3.4707 90.8351 4.06723 89.859 5.0976C88.8286 6.1822 88.2863 7.59218 88.2863 9.21908C88.2863 10.846 88.8286 12.256 89.859 13.3406C90.8894 14.3709 92.2451 14.9674 93.6009 14.9674ZM93.6009 4.60953C95.8243 4.60953 97.6139 6.50758 97.6139 9.16485C97.6139 11.8221 95.8785 13.7202 93.6009 13.7202C91.3775 13.7202 89.5879 11.7679 89.5879 9.16485C89.6421 6.56181 91.3775 4.60953 93.6009 4.60953Z"
            fill="white"
          />
          <path
            d="M47.7223 30.8569V22.3428H51.1388V20.0652H41.7028V22.3428H45.1193V30.8569H47.7223Z"
            fill="white"
          />
          <path
            d="M32.8634 20.0652H30.2603V30.9112H32.8634V20.0652Z"
            fill="white"
          />
          <path
            d="M24.7831 11.551C24.7831 10.141 24.0239 9.16491 22.5596 8.7853C23.8069 8.40569 24.5119 7.59224 24.5119 6.34495C24.5119 4.77228 23.2646 3.79614 20.8243 3.79614H18.0586V14.6421H20.282C23.3189 14.5879 24.7831 13.4491 24.7831 11.551ZM19.3059 4.82651H20.6074C22.397 4.82651 23.2646 5.31458 23.2646 6.50764C23.2646 7.64647 22.3427 8.35146 20.7158 8.35146H19.3059V4.82651V4.82651ZM19.3059 9.38183H20.5531C22.5054 9.38183 23.4816 10.1953 23.4816 11.4426C23.4816 12.7983 22.4512 13.5575 20.5531 13.5575H19.3059V9.38183Z"
            fill="white"
          />
          <path
            d="M39.9675 39.4794C39.9675 38.5574 39.5336 37.6355 38.7202 37.039C37.7983 36.3882 36.7137 36.2798 35.3579 36.2798H32.538V47.1258H35.0325V42.8958H35.4122L38.2863 47.1258H41.2148L37.961 42.5162C39.2625 41.9197 39.9675 40.8351 39.9675 39.4794ZM35.6291 41.2689H35.0868V38.2863H35.5206C36.9306 38.2863 37.6356 38.6659 37.6356 39.7505C37.5813 40.7266 36.9306 41.2689 35.6291 41.2689Z"
            fill="white"
          />
          <path
            d="M22.2343 38.5574H25.705V47.1257H28.308V38.5574H31.7245V36.334H22.2343V38.5574Z"
            fill="white"
          />
          <path
            d="M19.4686 40.5639L18.2213 39.8589C17.7332 39.5877 17.462 39.2624 17.462 38.8828C17.462 38.3405 17.8959 38.0151 18.6551 38.0151C19.2516 38.0151 20.1735 38.3405 21.3124 39.0454V36.4966C20.282 36.1713 19.3601 35.9543 18.6009 35.9543C16.4859 35.9543 15.0759 37.2559 15.0759 38.937C15.0759 40.2385 15.7267 41.2146 17.1909 42.0281L17.9501 42.4619C19.0347 43.1127 19.7397 43.3838 19.7397 44.1431C19.7397 44.7396 19.1974 45.1734 18.2755 45.1734C17.2451 45.1734 16.3232 44.7396 15.2386 44.0888L15.4555 46.963C16.5401 47.2884 17.462 47.5053 18.3297 47.5053C20.7701 47.5053 22.2885 45.9869 22.2885 43.9804C22.1258 42.245 21.0412 41.4316 19.4686 40.5639Z"
            fill="white"
          />
          <path
            d="M31.1822 14.9674C32.8091 14.9674 34.3818 14.154 35.4121 12.744C36.1171 11.7679 36.4967 10.5206 36.4967 9.21908C36.4967 7.59218 35.9544 6.1822 34.9241 5.0976C33.8937 4.013 32.5922 3.4707 31.1822 3.4707C29.7722 3.4707 28.4165 4.06723 27.4403 5.0976C26.41 6.1822 25.8677 7.59218 25.8677 9.21908C25.8677 10.846 26.41 12.256 27.4403 13.3406C28.4165 14.3709 29.7722 14.9674 31.1822 14.9674ZM31.1822 4.60953C33.4056 4.60953 35.1952 6.50758 35.1952 9.16485C35.1952 11.8221 33.4599 13.7202 31.1822 13.7202C28.9588 13.7202 27.1692 11.7679 27.1692 9.16485C27.1692 6.56181 28.9588 4.60953 31.1822 4.60953Z"
            fill="white"
          />
          <path
            d="M16.757 29.7181H11.9848V25.7593H16.0521V24.6747H11.9848V21.1498H16.7028V20.0652H10.7375V30.8569H16.757V29.7181Z"
            fill="white"
          />
          <path
            d="M86.6594 3.79614H85.3579V14.6421H86.6594V3.79614Z"
            fill="white"
          />
          <path
            d="M21.692 22.6682C21.692 23.9697 22.3427 24.9459 23.807 25.7593L24.5662 26.1931C25.6508 26.8439 26.3558 27.115 26.3558 27.8743C26.3558 28.4708 25.8135 28.9046 24.8916 28.9046C23.8612 28.9046 22.9393 28.4708 21.8547 27.82L22.0716 30.6942C23.1562 31.0196 24.0781 31.2365 24.9458 31.2365C27.3861 31.2365 28.9046 29.7181 28.9046 27.7116C28.9046 25.9762 27.7657 25.1628 26.1931 24.2951L24.9458 23.5901C24.4577 23.319 24.1866 22.9936 24.1866 22.614C24.1866 22.0717 24.6204 21.7463 25.3796 21.7463C25.9762 21.7463 26.8981 22.0717 28.0369 22.7767V20.2278C27.0065 19.9025 26.0846 19.6855 25.3254 19.6855C23.0477 19.6855 21.692 20.9871 21.692 22.6682Z"
            fill="white"
          />
          <path
            d="M38.8829 24.2951L37.6356 23.5901C37.1475 23.319 36.8764 22.9936 36.8764 22.614C36.8764 22.0717 37.3102 21.7463 38.0694 21.7463C38.6659 21.7463 39.5878 22.0717 40.7267 22.7767V20.2278C39.6963 19.9025 38.7744 19.6855 38.0152 19.6855C35.9002 19.6855 34.4902 20.9871 34.4902 22.6682C34.4902 23.9697 35.141 24.9459 36.6052 25.7593L37.3644 26.1931C38.449 26.8439 39.154 27.115 39.154 27.8743C39.154 28.4708 38.6117 28.9046 37.6898 28.9046C36.6594 28.9046 35.7375 28.4708 34.6529 27.82L34.8698 30.6942C35.9544 31.0196 36.8764 31.2365 37.744 31.2365C40.1844 31.2365 41.7028 29.7181 41.7028 27.7116C41.5944 25.9762 40.4555 25.1628 38.8829 24.2951Z"
            fill="white"
          />
          <path
            d="M77.4946 10.141H78.8503L82.6464 14.5879H84.2733L80.3688 9.97836C82.1041 9.70721 83.1887 8.45992 83.1887 6.94148C83.1887 4.93497 81.6703 3.79614 78.5792 3.79614H76.3015V14.6421H77.5488V10.141H77.4946ZM77.4946 4.82651H78.3623C80.6941 4.82651 81.833 5.42304 81.833 6.94148C81.833 8.35146 80.7484 9.16491 78.5792 9.16491H77.4946V4.82651Z"
            fill="white"
          />
          <path
            d="M6.67028 13.4491H1.57267V3.79614H0.271149V14.5879H6.67028V13.4491Z"
            fill="white"
          />
          <path
            d="M71.1497 0H69.7939L68.2755 2.60304H69.0347L71.1497 0Z"
            fill="white"
          />
          <path
            d="M85.7917 25.7593L86.551 26.1931C87.6356 26.8439 88.3405 27.115 88.3405 27.8743C88.3405 28.4708 87.7982 28.9046 86.8763 28.9046C85.846 28.9046 84.9241 28.4708 83.8395 27.82L84.0564 30.6942C85.141 31.0196 86.0629 31.2365 86.9306 31.2365C89.3709 31.2365 90.8894 29.7181 90.8894 27.7116C90.8894 25.9762 89.7505 25.1628 88.1779 24.2951L86.9306 23.5901C86.4425 23.319 86.1713 22.9936 86.1713 22.614C86.1713 22.0717 86.6052 21.7463 87.3644 21.7463C87.9609 21.7463 88.8828 22.0717 90.0217 22.7767V20.2278C88.9913 19.9025 88.0694 19.6855 87.3102 19.6855C85.1952 19.6855 83.7852 20.9871 83.7852 22.6682C83.6768 23.9697 84.3818 24.9459 85.7917 25.7593Z"
            fill="white"
          />
          <path
            d="M58.8937 14.5879H60.1952V4.93497H64.0456V3.79614H55.0976V4.93497H58.8937V14.5879Z"
            fill="white"
          />
          <path
            d="M48.9696 11.8763H54.0673L55.2603 14.642H56.6703L51.6269 3.5249H51.4642L46.4208 14.642H47.7766L48.9696 11.8763ZM51.5727 6.07371L53.6334 10.8459H49.4577L51.5727 6.07371Z"
            fill="white"
          />
          <path
            d="M9.38178 11.8763H14.4794L15.6725 14.642H17.0824L12.0391 3.5249H11.9306L6.88721 14.642H8.24296L9.38178 11.8763ZM11.9848 6.07371L14.0456 10.8459H9.86985L11.9848 6.07371Z"
            fill="white"
          />
          <path
            d="M65.5098 13.3406C66.4859 14.3709 67.8417 14.9674 69.2516 14.9674C70.8785 14.9674 72.4512 14.154 73.4816 12.744C74.1866 11.7679 74.5662 10.5206 74.5662 9.21908C74.5662 7.59218 74.0239 6.1822 72.9935 5.0976C71.9631 4.013 70.6616 3.4707 69.2516 3.4707C67.8417 3.4707 66.4859 4.06723 65.5098 5.0976C64.4794 6.1822 63.9371 7.59218 63.9371 9.21908C63.9913 10.846 64.5336 12.2017 65.5098 13.3406ZM69.2516 4.60953C71.4751 4.60953 73.2647 6.50758 73.2647 9.16485C73.2647 11.8221 71.5293 13.7202 69.2516 13.7202C67.0282 13.7202 65.2386 11.7679 65.2386 9.16485C65.2929 6.56181 67.0282 4.60953 69.2516 4.60953Z"
            fill="white"
          />
          <path
            d="M39.4252 10.141H40.7809L44.577 14.5879H46.2039L42.2994 9.97836C44.0347 9.70721 45.1193 8.45992 45.1193 6.94148C45.1193 4.93497 43.6009 3.79614 40.5098 3.79614H38.2321V14.6421H39.4794V10.141H39.4252ZM39.4252 4.82651H40.2929C42.6247 4.82651 43.7636 5.42304 43.7636 6.94148C43.7636 8.35146 42.679 9.16491 40.5098 9.16491H39.4252V4.82651Z"
            fill="white"
          />
          <path
            d="M16.6486 52.2776V55.8025H0V52.2776H16.6486Z"
            fill="#78BAB8"
          />
          <path
            d="M33.3514 52.2776V55.8025H16.6486V52.2776H33.3514Z"
            fill="#576DB0"
          />
          <path d="M50 52.2776V55.8025H33.2972V52.2776H50Z" fill="#6761A6" />
          <path
            d="M66.6486 52.2776V55.8025H50V52.2776H66.6486Z"
            fill="#986197"
          />
          <path
            d="M83.2972 52.2776V55.8025H66.6486V52.2776H83.2972Z"
            fill="#C04E5B"
          />
          <path d="M100 52.2776V55.8025H83.3514V52.2776H100Z" fill="#C88E51" />
        </g>
        <defs>
          <clipPath id="clip0_1432_717">
            <rect width="100" height="88.7202" fill="white" />
          </clipPath>
        </defs>
      </SponsorsIcon>
    </a>
  );
};
