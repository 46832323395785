import React from "react";
import { SocialIcon } from "./styles";

export const Instagram = ({ url, color }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <SocialIcon
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5597 9.69042C21.4835 9.64083 21.1597 9.63208 18.4341 9.63208C15.7085 9.63208 15.3862 9.64229 14.3099 9.69042C11.5405 9.81729 10.2514 11.1283 10.1245 13.8758C10.0764 14.9521 10.0647 15.2744 10.0647 18C10.0647 20.7256 10.0764 21.0479 10.1245 22.1256C10.2514 24.8658 11.5347 26.1842 14.3099 26.311C15.3847 26.3592 15.7085 26.3708 18.4341 26.3708C21.1612 26.3708 21.4835 26.3606 22.5597 26.311C25.3291 26.1856 26.6168 24.8702 26.7451 22.1256C26.7932 21.0494 26.8035 20.7256 26.8035 18C26.8035 15.2744 26.7932 14.9521 26.7451 13.8758C26.6168 11.1298 25.3262 9.81729 22.5597 9.69042ZM18.4341 23.2427C15.5393 23.2427 13.1914 20.8962 13.1914 18C13.1914 15.1052 15.5393 12.7588 18.4341 12.7588C21.3289 12.7588 23.6768 15.1052 23.6768 18C23.6768 20.8948 21.3289 23.2427 18.4341 23.2427ZM23.8839 13.7767C23.2072 13.7767 22.6589 13.2283 22.6589 12.5517C22.6589 11.875 23.2072 11.3267 23.8839 11.3267C24.5605 11.3267 25.1089 11.875 25.1089 12.5517C25.1089 13.2269 24.5605 13.7767 23.8839 13.7767ZM21.8364 18C21.8364 19.8798 20.3124 21.4023 18.4341 21.4023C16.5557 21.4023 15.0318 19.8798 15.0318 18C15.0318 16.1202 16.5557 14.5977 18.4341 14.5977C20.3124 14.5977 21.8364 16.1202 21.8364 18ZM18.4341 0.5C8.76971 0.5 0.934082 8.33563 0.934082 18C0.934082 27.6644 8.76971 35.5 18.4341 35.5C28.0985 35.5 35.9341 27.6644 35.9341 18C35.9341 8.33563 28.0985 0.5 18.4341 0.5ZM28.5812 22.2087C28.4135 25.9202 26.347 27.9765 22.6443 28.1471C21.5549 28.1967 21.2064 28.2083 18.4341 28.2083C15.6618 28.2083 15.3147 28.1967 14.2253 28.1471C10.5153 27.9765 8.45762 25.9173 8.287 22.2087C8.23742 21.1208 8.22575 20.7723 8.22575 18C8.22575 15.2277 8.23742 14.8806 8.287 13.7913C8.45762 10.0813 10.5168 8.02354 14.2253 7.85438C15.3147 7.80333 15.6618 7.79167 18.4341 7.79167C21.2064 7.79167 21.5549 7.80333 22.6443 7.85438C26.3557 8.025 28.4149 10.0885 28.5812 13.7913C28.6308 14.8806 28.6424 15.2277 28.6424 18C28.6424 20.7723 28.6308 21.1208 28.5812 22.2087Z"
          fill={color}
        />
      </SocialIcon>
    </a>
  );
};

Instagram.defaultProps = {
  color: "#D9D9D9",
};
